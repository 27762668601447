import React from "react";
import './App.css';
import PDF from "./document.pdf";
import PdfViewer from "./PdfViewer";
import  { useState, useEffect } from 'react';


function App() {

  const [pdfUrl, setPdfUrl] = useState('https://cors-anywhere.herokuapp.com/https://aws.eleletdigital.com/App/ComplianceCalendar.pdf');

useEffect(() => {
   const queryString =window.location.search
    const params = new URLSearchParams(queryString);
    console.log(params)
    const paramValue = params.get('document');
    console.log(paramValue)
	setPdfUrl(paramValue)


  }, []);

  return(
    <PdfViewer pdf={pdfUrl} />
  );
}

export default App;
